<template lang="pug">
.sidebar-input-wrapper
  .sidebar-input-wrapper-label
    label(v-if="label") {{ $t(label) }}
  span.epic-simple-input.epic-simple-input-wide
    input.simpleInput.cursor-pointer(
      :value="value"
      @input="$emit('input', $event)"
      :placeholder="placeholder"
      :readonly="readonly"
      :class="{ invalid, small }"
    )
    .epic-simple-input-icon(v-if="$slots.icon")
      slot(name="icon")
</template>
<script>
  import itemMixin from '@/editor/mixins/item';
  import sidebarInputMixin from '@/mixins/sidebar/input';

  export default {
    mixins: [itemMixin, sidebarInputMixin],
    props: {
      value: { type: [String, Number, null], default: null },
      label: { type: String, default: null },
      placeholder: { type: String, default: null },
      readonly: { type: Boolean, default: false },
      small: { type: Boolean, default: false },
    },
  };
</script>

<style lang="sass" scoped>
  .sidebar-input-wrapper
    padding: 0
    width: 100%
    .small
      height: 30px
</style>
